<script setup lang="ts">
import anonymousAvatar from '../assets/images/avatars/anonymous.png'

interface UserAvatarProps {
  username?: string | null
  avatar?: string | null
}

const props = withDefaults(defineProps<UserAvatarProps>(), {
  username: null,
  avatar: null,
})

const avatar = computed((): string => {
  if (props.avatar) return props.avatar
  if (!props.username) return anonymousAvatar
  // Use DiceBear to generate an avatar based on the username
  const sanitizedUsername = props.username.replace(/[^a-z0-9]+/i, '-')
  return `https://api.dicebear.com/6.x/bottts-neutral/svg?seed=${sanitizedUsername}&backgroundColor=b6e3f4,c0aede,d1d4f9&eyes=bulging,dizzy,eva,frame1,frame2,glow,happy,robocop,round,roundFrame01,roundFrame02,sensor,shade01`
})

const username = computed((): string | null => props.username)
</script>

<template>
  <div class="max-w-10 max-h-10">
    <img
      :src="avatar"
      :alt="username ?? 'User Avatar'"
      class="block h-auto w-full max-w-full rounded-full bg-pumice-100 dark:bg-nickel-800"
    />
    <slot />
  </div>
</template>
